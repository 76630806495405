

import { FAQs } from "../../../../components/onboarding/faqs";
import { opponentExplanation, selectAllFaq } from "../common/copy";

export function CreateTeamModelFAQs(styleProps) {

    var faqItems = [
        {
            "summary" : "Will selecting all stats create the best model?",
            "details" : selectAllFaq
        },
        {
            "summary" : 'What happens when I click "Run"?',
            "details" : "A new model is trained using the statistics that were chosen. When complete, you will be redirected to a new page \
                that will breakdown the performance of your model."
        },
        {
            "summary" : 'What do "Opponent" stats mean in context?',
            "details" : opponentExplanation
        }
    ]

    return (
        <>
            <FAQs faqItems={faqItems} className='onboarding-step-4' styleProps={styleProps} />
        </>
    )
}