
import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { ChangelogGraphic } from '../../../components/graphics/changelog';

export function Changelog(){

    return (
        <Container maxWidth="md">
            <Stack direction="column" spacing={4} divider={<Divider />}>
                <Typography variant="h3">Changelog</Typography>
                {changelog_data.map((item,index) => (
                    <LogContent 
                    key={index}
                    date={item.date}
                    imageSrc={item.imageSrc}
                    title={item.title}
                    details={item.details}
                    />
                ))}
            </Stack>
        </Container>
    )
}

function AdditionalMetricsGridItem({league,numberAdded,numberRemoved,highlights}){

    return (
        <Grid item xs={12} md={6} sx={{pl:1, pr: 1}}>
            <Card sx={{borderRadius: 2}}>
                <CardHeader
                    title={league}
                    titleTypographyProps={{variant: "h6"}}
                    subheader={highlights}
                    subheaderTypographyProps={{
                            height: 100
                    }}
                />

                <CardContent>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Typography color="success.dark" variant="h5">+ {numberAdded}</Typography>
                        <Typography color="error.dark" variant="h5">- {numberRemoved}</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

const additionMetricsDetails = (
    <Stack direction="column" spacing={2}>
        <Typography variant="body">
            Based on user feedback, our team improved our offering of statistics for all leagues. We added a multitude
            of new metrics, and removed some duplicate and unnecessary ones. The majority of NFL and NCAAF stats 
            that we added were defensive versions of offensive metrics that we already offer, and this led to a HUGE
            improvment in these new models' performance.
        </Typography>
        <Typography variant="body">
            See below for details on how each league was effected. Additionally, we improved our 
            FAQs to better explain what an Opponent stat means.
        </Typography>
        <Grid container rowSpacing={2} justifyContent="flex-start">
            <AdditionalMetricsGridItem 
            league={"NFL"}
            numberAdded={45}
            numberRemoved={5}
            highlights={"Added Point Differential, Defensive Third and Fourth Down Efficiencies, \
                Defensive Goal to Go Efficiencies, and Kickoff Average Field Position."}
            />
            <AdditionalMetricsGridItem 
            league={"NBA"}
            numberAdded={28}
            numberRemoved={1}
            highlights={"The offensive and defensive Four Factors are now available. Use the 'Four Factors' category filter \
                to easily find them when creating a model."}
            />
            <AdditionalMetricsGridItem 
            league={"NCAAF"}
            numberAdded={25}
            numberRemoved={5}
            highlights={"Added Point Differential, and defensive equivalents for offensive metrics that were missing."}
            />
            <AdditionalMetricsGridItem 
            league={"NCAAB"}
            numberAdded={16}
            numberRemoved={0}
            highlights={"The offensive and defensive Four Factors are now available. Use the 'Four Factors' category filter \
                to easily find them when creating a model."}
            />
        </Grid>
    </Stack>
)

const ModelEditingDetails = (
    <List sx={{listStyle: 'disc', pl: 2}}>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Tweak and improve your models by using the new Edit feature from your profile or the model summary page." 
            />
            <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
                <ChangelogGraphic file='./ProfileEditExample.png' width="100%" maxWidth={{xs:300,md:400}} />
            </Stack>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Reduced the effects of outliers to further optimize team models. Silently re-trained all existing team models to
            include this optimization." 
            />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Added stat count display when creating a model, and on summary page." 
            />
        </ListItem>
    </List>
)

const changelog_data = [
    {
        date: dayjs('2024-10-05'),
        imageSrc: null,
        title: "Model Editing and Team Model Optimization",
        details: ModelEditingDetails
    },
    {
        date: dayjs('2024-09-19'),
        imageSrc: null,
        title: "Additional Metrics",
        details: additionMetricsDetails
    },
    {
        date: dayjs('2024-09-09'),
        imageSrc: null,
        title: "Site Launch",
        details: <Typography variant="body"></Typography>
    },
]


// details should be a react component, the default would be
// to use Typpography variant=body
function LogContent({date,imageSrc,title,details}){

    return (
        <Stack direction={{xs:"column",sm:"row"}} spacing={{xs:2, sm:5}} alignItems="flex-start" >
            <Typography color="textDisabled" sx={{minWidth: 120}}>{date.format('MMM Do, YYYY')}</Typography>

            <Stack direction="column" spacing={2}>
                {imageSrc &&
                <Typography>Insert image here</Typography>
                }
                <Typography variant="h4">{title}</Typography>
                {details}
            </Stack>
        </Stack>
    )
}