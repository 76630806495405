
import { FlaskClient } from "./setup";

export const playerTrainRequest = (requestBody,params,handleResponse,handleError) => {
    FlaskClient.post('player/train',
      requestBody,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerPredictionsRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/predictions',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSummaryRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/summary')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSlimSummaryRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/summary',
    {
    params: {slim: true}
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

export const playerDeleteRequest = (params,handleResponse,handleError) => {
    FlaskClient.delete('player/delete',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerStoreRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('player/store',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

export const playerScheduleRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/schedule',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerUpdateRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('player/update',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSaveRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('player/save',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerModelsRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/models')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerStatisticsRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/statistics',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerValidDatesRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/valid-dates',
    {
      params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }