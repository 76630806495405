import {
    Box,
    Button,
    Paper,
    Stack,
    Typography
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useAuth} from '../../../hooks/auth';
import TeamPredictionImageSrc from '../../../assets/graphics/landing-page/TeamPredictionGraphic.webp';

export function HeaderTextAndAction() {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const auth = useAuth();

    const buttonSize = isMobile ? "small" : "large";

    const handleHowItWorks = (event) => {
        const element = document.getElementById('how-it-works');
        element?.scrollIntoView({
            behavior: 'smooth'
        }); 
    }

    return (
        <>
        <Stack direction="column" alignItems={{xs: 'center', md: 'flex-start'}} textAlign={{xs: 'center', md: 'left'}} spacing={3}>
            <Typography gutterBottom variant="h3" component="div">
                    Custom Sports Models using Machine Learning
            </Typography>
            <Typography variant="h6" component="div">
                    Predict team scores and individual player performances by choosing the statistics 
                    that <strong>YOU</strong> think impact the outcome. 
            </Typography>
            <Typography variant="h6" component="div">
                Trillions of combinations. Anyone can be the one to solve sports.
            </Typography>

            <Stack direction="row" spacing={2} sx={{ pt: 2}}>
                {!auth.loading && !auth.authenticated ?
                <Button size={buttonSize} onClick={()=> {navigate('/register')}}>
                    Try for free
                </Button>
                :
                <Button size={buttonSize} onClick={()=> {navigate('/changelog')}}>
                    What's new?
                </Button>
                }
                <Button variant="outlined" size={buttonSize} onClick={handleHowItWorks}>
                    See how it works
                </Button>
            </Stack>
        </Stack>
        </>
    );
}

function HeaderGraphic(){

    return (
        <Paper variant="outlined" sx={{ textAlign: 'center', width: {xs:'90%',md: '80%'}, height: "100%"}}>
            <Box sx={{alignItems: 'center', p: {xs:0.5,md:2}}}>
                <Box 
                component="img"
                src={TeamPredictionImageSrc}
                alt="Prediction Example"
                height={"auto"}
                width={"100%"}
                />
            </Box>
        </Paper>
    )
}

export function Header(){

    return (
        <Stack direction={{xs:"column",md:"row"}} alignItems={{xs:'center',md:'flex-start'}} spacing={{xs:4,md:2}}
        sx={{p: 2 }}
        >

            <HeaderTextAndAction />
            <HeaderGraphic />

        </Stack>
    )
}