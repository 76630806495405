// Basic Component Imports
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Stack} from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { PlayerStatSelection } from '../components/player/player-stat-selection-table';
import { CreatePlayerModelFAQs } from '../components/player/faqs';
import { CreatePlayerModelWalkthrough } from '../../../components/onboarding/walkthroughs';
import { CreatePlayerModelHeader } from '../components/common/headers';
import { SeoHelmet } from '../../../components/seo';

export default function CreatePlayerModel() {
    const { target } = useParams();

    const location = useLocation();

    const [editEnabled,setEditEnabled] = useState(false);

    useEffect(() => {
        setEditEnabled(location.state !== null ? location.state.edit_enabled : false);
    },[location]);

    return (
        <>
            <SeoHelmet 
            title="Create Player Prop Model"
            description="Create a custom betting model to predict player props for NFL and NBA."
            />
            <ResponsiveContainer>
                <CreatePlayerModelHeader league={target} />
                <Stack direction="row" justifyContent="flex-end">
                    <CreatePlayerModelWalkthrough />
                </Stack>
                <PlayerStatSelection league={target} editEnabled={editEnabled} setEditEnabled={setEditEnabled}/>
                <CreatePlayerModelFAQs />
            </ResponsiveContainer>
        </>
    );
}